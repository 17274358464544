import { Box, SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { SectionModel } from '@shared/models/config';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskEditViewModel } from '../../../viewmodels';
import { DropDownSelector } from '../../utils';

export interface TaskEditSectionSelectorProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskEditViewModel;
}

export const TaskEditSectionSelector = observer((props: TaskEditSectionSelectorProps) => {
  const { localizationService } = useStudyoServices();
  const { viewModel, sx = [], className } = props;
  const theme = useTheme();

  const sortedSections = useMemo(() => {
    return viewModel.allSections.sort((s1, s2) =>
      s1.title.localeCompare(s2.title, localizationService.currentLocale, { sensitivity: 'base' })
    );
  }, [viewModel.allSections, localizationService.currentLocale]);

  const options = ['', ...sortedSections.map((s) => s.id)];

  const selectedIndex = useMemo(() => {
    const { selectedSection } = viewModel;

    if (selectedSection == null) {
      return 0;
    } else {
      const sectionIndex = sortedSections.findIndex((section) => section.id === selectedSection.id);
      return sectionIndex >= 0 ? sectionIndex + 1 : 0;
    }
  }, [viewModel.selectedSection, sortedSections]);

  const getTitleForSection = (section: SectionModel | undefined) => {
    if (section != null) {
      return `${section.title}${section.sectionNumber.length > 0 ? ' - ' + section.sectionNumber : ''}`;
    } else {
      return localizationService.localizedStrings.studyo.contents.taskEdit.noneSection;
    }
  };

  return (
    <DropDownSelector
      sx={sx}
      className={className}
      options={options}
      selectedIndex={selectedIndex}
      didSelectOption={(index) => viewModel.setSectionId(options[index])}
      disabled={!viewModel.canEditSection}
      displayIconForOption={(option) => {
        const section = option != null ? sortedSections.find((s) => s.id === option) : undefined;
        const sectionColor =
          section != null
            ? SectionColors.get(viewModel.getSectionColor(section))
            : theme.studyo.periods.freePeriodColor;

        return <Box sx={{ width: 26, height: 26, backgroundColor: sectionColor, borderRadius: 1 }} />;
      }}
      displayValueForOption={(option) => {
        const section = option != null ? sortedSections.find((s) => s.id === option) : undefined;
        return getTitleForSection(section);
      }}
    />
  );
});
